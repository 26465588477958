<template>
  <div class="">
    <div class="uk-box-shadow-medium uk-background-default uk-padding-small brd12">
      <div class="uk-width-large">
        <div class="uk-text-left uk-margin-top">
          <div class="fnt-bld">Youtube ID Video</div>
          <div class="uk-margin-small-top">
            <input class="uk-input" type="text" v-model="video.video_id">
          </div>
          <div v-if="video.video_id" class="uk-position-relative">
            <img src="/images/iframe_min.jpg" alt="">
            <iframe class="uk-position-absolute uk-width-1-1 uk-height-1-1 uk-position-top-left" :src="`https://www.youtube.com/embed/${video.video_id}`" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
            </iframe>
          </div>
        </div>

        <div class="uk-text-left uk-margin-top">
          <div class="fnt-bld">Название видео</div>
          <div class="uk-margin-small-top">
            <input class="uk-input" type="text" v-model="video.name">
          </div>
        </div>

        <div class="uk-text-left uk-margin-top">
          <div class="fnt-bld">Категории</div>
          <div class="uk-form-controls uk-margin-small-top">
            <select class="uk-select" v-model="video.categories" multiple>
              <option v-for="(category, i) in categories" :key="i" :value="category.id_category">
                {{ category.category_name }}
              </option>
            </select>
          </div>
        </div>

        <div class="uk-text-left uk-margin-top">
          <div class="fnt-bld">Дата события</div>
          <div class="uk-margin-top">
            <input
              class="uk-select"
              v-model="video.date_event"
              type="date" data-uk-datepicker="{format:'DD.MM.YYYY'}">
          </div>
        </div>
        <div class="uk-text-left uk-margin-top">
          <div class="fnt-bld">Описание видео</div>
          <div class="uk-margin-small-top">
            <textarea class="uk-padding-small uk-width-1-1 uk-height-medium fs16" v-model="video.description" style="resize: none;"></textarea>
          </div>
        </div>
      </div>

    </div>

    <div class="">
      <button class="btn uk-margin-medium-top bg-church brd36 cl-wh show-animation fs16 uk-box-shadow" @click="addVideo">
        Добавить видео
      </button>
    </div>
  </div>
</template>


<script>
import UIkit from 'uikit'
  export default {
    data() {
      return {
        video: {
          name: '',
          video_id: '',
          categories: [],
          description: '',
          date_event: '',
          church_id: 1,
        },
        categories: [],
      }
    },

    created() {
      this.$api.post('video/get_cats').then(rs => {
        this.categories = rs.data
      })
    },

    methods: {
      addVideo() {
        if (!this.video.video_id) {
          UIkit.notification({
            message: 'Укажите ID Video',
            status: 'danger',
            pos: 'top-right',
          });
          return
        }

        if (!this.video.name) {
          UIkit.notification({
            message: 'Укажите пожалуйста название',
            status: 'danger',
            pos: 'top-right',
          });
          return
        }
        if (!this.video.categories.length) {
          UIkit.notification({
            message: 'Укажите категорию',
            status: 'danger',
            pos: 'top-right',
          });
          return
        }

        if (!this.video.date_event) {
          UIkit.notification({
            message: 'Укажите дату события',
            status: 'danger',
            pos: 'top-right',
          });
          return
        }

        this.$api.post('admin/video/add', this.video)
        .then(rs => {
          if (rs.data.id) {
            UIkit.notification({
              message: 'Видео успешно добавлено',
              status: 'success',
              pos: 'top-right',
            });
          }
        })
      }
    }
  }
</script>
